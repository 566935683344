<template>
  <div id="logout">
    <v-layout>
      <v-flex xs12 sm12 md12 lg12>
        <v-card>
          <v-card-title primary-title> Logout successful</v-card-title>
          <v-card-text>
            <p>You have logged out, redirecting to login page... </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<style lang="stylus">
  #logout
    text-align: center
    margin: auto
    width: 40%
    display: block
    margin-top: 6rem

</style>

<script>
  export default {
    data () {
      return {
        context: 'logout context',

        error: null
      }
    },

    mounted () {
      // logging out user
      this.logout()
    },

    methods: {
      logout: function () {        
        this.$auth.logout({
          makeRequest: true,
          redirect: { name: 'login' },
          success () {
            
          },
          error () {}
        }).then(() => {
          // Clear vuex state
          window.location.reload
        })
      }
    }
  }
</script>
